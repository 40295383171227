import React from "react";

interface IFeatureBlockProps {
  icon: any;
  title: string;
  link: string;
}

export default function FeatureBlock({
  icon,
  title,
  link,
}: IFeatureBlockProps) {
  return (
    <a className="featureblock" href={link}>
      {icon}
      <h3>{title}</h3>
    </a>
  );
}
