import React from "react";

import Layout from "../../components/Layout";
import FeatureBlock from "../../components/FeatureBlock";

import tr from "../../components/Translations.json";
import "../../styles/SelectionPage.scss";
import setIcon from "../../components/FeatureIcon";

export default function AdvancedIndex() {
  let guides = tr.fr.guides;

  let xguides = guides.map((item) => (
    <FeatureBlock
      title={item.name}
      link={item.link}
      icon={setIcon(item.icon)}
    />
  ));

  return (
    <Layout title="Guides" noscroll>
      <div className="features-root">
        <h1>Guides</h1>
        <div className="guides">{xguides}</div>
      </div>
    </Layout>
  );
}
